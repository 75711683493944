<template>
<b-dropdown :disabled="disabled" block class="dropdown-select">
  <template #button-content>
    <div class="d-flex justify-content-between align-items-center form-control form-control-solid">
      <div class="d-flex align-items-center" :class="[{'form-control is-invalid px-0': invalid}]"
           style="overflow: hidden; width: calc(100% - 20px)"
      >
        <div class="d-flex justify-content-between flex-nowrap">
          <div class="d-flex align-center" style="">
            <template v-if="selectedOptions">
              <slot name="selected">
                {{ callOptionName(selectedOptions) }}
              </slot>
            </template>
          </div>
        </div>
        <span v-if="!selectedOptions" v-once
              class="text-muted"
        >{{ placeholder || $t('PLACEHOLDER.SELECT') }}</span>
      </div>
      <div class="d-flex">
        <div v-if="selectedOptions && nullable"
             class="svg-icon svg-icon-secondary svg-icon-md d-flex align-center cursor-pointer mr-3"
             @click.stop="select(null)"
        >
          <!--suppress HtmlUnknownTag, HtmlUnknownTarget -->
          <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
        </div>
        <span class="svg-icon svg-icon-md mr-0">
          <!--suppress HtmlUnknownTag, HtmlUnknownTarget -->
          <inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
        </span>
      </div>
    </div>
  </template>
  <b-dropdown-header>
    <b-form-input v-model="search" :placeholder="$t('BASE.SEARCH')" class="form-control-solid" debounce="300" trim
                  type="text"
    />
  </b-dropdown-header>
  <options
    :empty="optsEmpty"
    :keepOpen="false"
    :key-resolver="callOptionName"
    :loader="load"
    :loading="pager.loading"
    :options="opts"
    :selection="selectedOptions"
    :value-resolver="callOptionValue"
    @select="select"
  />
</b-dropdown>
</template>

<script>
import generic from './generic'

export default {
  name: 'Single',
  mixins: [generic],
  props: {
    value: null,
  },
  data() {
    return {
    }
  },
  computed: {
    selectedOptions: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    selected(option) {
      if (this.callOptionValue(this.selectedOptions) === this.callOptionValue(option)) {
        return this.selectedOptions
      }
      return option
    },
    select(option) {
      this.selectedOptions = option
    },
  },
}
</script>
